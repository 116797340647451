<template>
  <main>
    <div v-if="!settings">

    <h1>Privacy & Legal</h1>
    <h4 style="font-weight: normal;">We will add information to this page soon.</h4>
    </div>
    <div v-else v-html="settings.legal">

    </div>
  </main>
</template>

<script>
import titleMixin from "@/mixins/titleMixin";
import {mapState} from "vuex"
export default {
  name: "Legal",
  computed:{
    ...mapState(['settings']),
  },
  mixins:[titleMixin],
  title(){
    return 'Privacy & Legal'
  },
}
</script>

<style scoped>

</style>
